const Projects = {
   "xypnox/todxpy":"TodXpy",
   "shashvat6264/groovy-slack":"SlackGroovy",
   "cimplec/sim-c":"sim-C",
   "documentive/templatebuddy":"TemplateBuddy",
   "akshgpt7/youtube-deno":"youtube-deno",
   "akshgpt7/marvellos":"MarvellOS",
   "abhijit2505/edualgo":"eduAlgo",
   "abhijit2505/edualgo-app-demo":"eduAlgo-app",
   "sinha-debojyoti/ookla-speedtest.net-crawler":"Ookla-Speedtest.net-Crawler",
   "sudo-rgorai/darksite":"DarkSite",
   "canvasbird/canvasboard":"Canvasboard",
   "expresshermes/singular-news":"Singular News",
   "expresshermes/blog-api":"Blog App",
   "expresshermes/helping-hand":"Helping Hand",
   "yashrsharma44/grpc-chat-app":"gRPC-Chat-App",
   "team-tomato/qp-generator":"QP-Generator",
   "codezonediitj/pydatastructs":"PyDataStructs",
   "prashantsengar/cleanpy":"CleanPy",
   "prashantsengar/mtracker":"Email Tracker",
   "prashantsengar/redins":"RedIns",
   "prashantsengar/timetracker":"TimeTracker",
   "iamshubhankarkhare/chat-application":"Buzz and go",
   "rohan-cod/covid19_tracker_and_voice_assistant":"COVID19 TRACKER AND VOICE ASSISTANT",
   "rohan-cod/newspaper-app":"Newspaper App",
   "rohan-cod/music_playlist":"Music Playlist App",
   "97shivank/hotel-site-with-django":"Hotel-Site-with-Django",
   "rohan-cod/tinder_bot":"Tinder bot",
   "rohan-cod/idine":"iDine",
   "surajbokde/nature_beauty":"Nature_Beauty (web App)",
   "surajbokde/web-development-resource":"Web-development-Resource (hacks, tips, tricks ,documentation)",
   "harshcasper/rotten-scripts":"Rotten Scripts",
   "harshcasper/speaked":"SpeakEd",
   "tejaswini22199/knowledgeshare":"KnowledgeShare",
   "garimasingh128/gitsetgo":"GitSetGo",
   "garimasingh128/awesome-python-projects":"awesome-python-projects",
   "garimasingh128/draw":"Draw",
   "technical-hackers/health-log":"Health-Log",
   "jedi18/syl3d":"Syl3D",
   "python-world/joble":"Joble",
   "grapheo12/iqps":"IQPS",
   "adixcodr/node-ocr":"Node-OCR",
   "pragativerma18/snippet-share":"Snippet Share",
   "chromicle/amrita-resource":"Amrita Resource",
   "hitk-tech-community/community-website":"Community-website",
   "raghwendra-dey/productivity_meter":"productivity_meter",
   "daemon1024/paste.land":"paste.land",
   "govindgoel/collegebuddy":"CollegeBuddy",
   "ankingcodes/typecode":"TypeCode",
   "ankingcodes/codemon":"codemon",
   "dharmesh-poddar/precision-farming":"Precision-Farming",
   "vipul-goyal/salesforce-batch-to-delete-records":"Salesforce Enhancements",
   "wildfirepy/wildfirepy":"WildfirePy",
   "thanos-io/thanos":"Thanos-IO",
   "anirban166/world-lines":"World Lines",
   "supu2701/otes-app":"Android App- OTES",
   "blackcipher101/camscanner_webapp":"CamSanx",
   "blackcipher101/spaceye":"Spaceye",
   "freezingmoon/ancientbeast":"Ancient Beast",
   "utkarsh0702/pothole-detection-visualization":"Pothole Detection and Visualization",
   "apprenticearnab/reinforcebots":"ReinforceBots",
   "namanbhatia7/false9":"False9",
   "petalsonwind/statistics-and-econometrics-for-data-science":"Statistics and Econometrics for Data Science",
   "ashwinkey04/lorax":"Lorax",
   "raag-music/raag":"Raag",
   "rsrkpatwari1234/echoforme":"EchoForMe",
   "nihighlism/dystuss":"DYSTuss",
   "kislaya-sri/the-metro-app":"THE METRO APP",
   "vikasgola/coding-list":"Coding List",
   "vikasgola/image-filtering":"Image-Filtering",
   "prami1806/work-at-a-startup":"Work at a startup",
   "lethicalai/lethical_lib":"Lethical.ai",
   "saikat2019/hiyo-chat_anonymously":"HiYo-Chat anonymously ",
   "pushpinderpalsingh/spacedash":"SpaceDash",
   "pushpinderpalsingh/gigme":"GigMe",
   "codestromer/contestreminder":"Contest Reminder",
   "supu2701/open-cv-projects":"Solve the Maze",
   "bot-academia/nebula-frontend":"Nebula-Frontend",
   "aswinigh/react-anim-text":"React-anim-text",
   "pshishod2645/goc-website":"Grimore of Code's Website",
   "bot-academia/nebula-backend":"Nebula Backend",
   "americast/algo_bias":"AI Bias",
   "arjungarg07/brief-me":"Brief-Me",
   "adityabisoi/fetch":"fetch",
   "adityabisoi/ds-algo-solutions":"ds-algo-solutions",
   "adityabisoi/startup-incubator":"startup-incubator",
   "adityabisoi/notation-converter":"notation-converter",
   "robusttechie/imagex":"ImageX",
   "graph-visualization/graph-api":"Graph-Visualization",
   "priyanshux/cryptopy":"CryptoPy",
   "soumyajit1729/smart-teaching":"Smart Teaching",
   "codextream/cgpa_estimator":"CGPA_ESTIMATOR",
   "garg3133/jagratiwebapp":"Jagrati WebApp",
   "github.com/the-browser-toolbox":"The Browser Toolbox",
   "liber/":"Liber",
   "shrinish123/mumble":"Mumble",
   "priyanshux/fpl-defence-rotations":"FPL Defence Rotations",
   "kunaljain0212/apni-dukan-frontent":"Apni Dukan",
   "sam17/getfit":"Getfit",
   "diyajaiswal11/bloggitt":"Bloggitt",
   "igkishore/lab_portal":"Full Stack Lab Websiye",
   "shivam1423/pest-detection":"Pest Detection",
   "vedangj044/ufo-sighting":"Frisson",
   "nuwandavek/okru":"okru",
   "ryash/backup-restore-utility-for-linux":"Backup-Restore-Utility-for-Linux",
   "aashimgarg/migrating-floeproject":"Migrating to static site generator",
   "thescriptninja/memelord":"Memelord",
   "lttkgp/c-3po":"C-3PO",
   "lttkgp/r2-d2":"R2-D2",
   "shruti170901/leetcode":"Leet-code",
   "baidi-mannan/divide-game":"DIVIDE-GAME",
   "ultra-utsav/book-rentals":"Book-Rentals",
   "iamskab/painter":"Painter",
   "iharshit009/secretpeek":"Secret Peek",
   "santamrc/postman":"Postman",
   "rits1272/memegram":"Memegram",
   "santamrc/elixir":"Elixir",
   "enzaimz/c_made_easy":"C Programming made easy",
   "rohitsingh30/shortest_path_blockage":"Optimizing shortest path after Road Blockage",
   "geekquad/algobook":"AlgoBook",
   "diyajaiswal11/bookstore":"BookStore",
   "plazzy99/vendorrelief":"Vendor Relief",
   "ryash/attendance-via-face-recognition":"Attendance-via-Face-Recognition",
   "ayush7-bit/tinder_website_for_dogs":"DOG_TINDER_WEBSITE",
   "kamandprompt/codemaniacs":"CodeManiacs",
   "marauders-9998/attendance-management-using-face-recognition":"Attendance-Management-using-Face-Recognition",
   "lttkgp/falcon":"Falcon",
   "vibhu-agarwal/potter_spells":"potter-spells",
   "abhipachorkar296/nyx":"Nyx",
   "shrinish123/guessmovie":"GuessMovie",
   "anand-aman/work_today":"Work Today App",
   "nihighlism/minerva":"Minerva",
   "akshayaky/gicca":"gICCa",
   "tech-phantoms/quizzer":"Quizzer",
   "im-prakher/my-notes-app":"Notes App",
   "dheerajkotwani/newsup":"NewsUp",
   "imabp/webterminal":"Web Terminal",
   "the-browser-toolbox/url-shortener":"url-shortener",
   "the-browser-toolbox/to-do":"To-do extension",
   "the-browser-toolbox/save-notes":" Save-notes extension",
   "the-browser-toolbox/usage-monitor":"Usage-monitor extension",
   "tharun143/kwoc-ems":"Enterprise Management System",
   "inaxia/hashcode_web": "Hashcode Web",
   "inaxia/hashcode_app": "Hashcode App"
}

export default Projects
