import Particles from 'react-particles-js';
import React from 'react';

export default function Part() {
	return (
		<Particles
			className="particles"
			params={{
				particles: {
					number: {
						value: 350,
						density: {
							enable: true,
							value_area: 1000
						}
					},
					color: {
						value: [ '#fff', '#9552EA' ]
					},
					shape: {
						type: 'circle'
					},
					opacity: {
						value: 0.5,
						random: true,
						anim: {
							enable: true,
							speed: 1,
							opacity_min: 0.1,
							sync: false
						}
					},
					size: {
						value: 10,
						random: true,
						anim: {
							enable: false,
							speed: 40,
							size_min: 0.1,
							sync: false
						}
					},
					line_linked: {
						enable: false
					},
					move: {
						enable: true,
						speed: 3,
						direction: 'bottom',
						random: false,
						straight: false,
						out_mode: 'out',
						bounce: false,
						attract: {
							enable: false,
							rotateX: 600,
							rotateY: 1200
						}
					}
				},
				interactivity: {
					detect_on: 'canvas',
					events: {
						onhover: {
							enable: true,
							mode: 'bubble'
						},
						onclick: {
							enable: true,
							mode: 'repulse'
						},
						resize: true
					},
					modes: {
						bubble: {
							distance: 400,
							size: 4,
							duration: 0.3,
							opacity: 1,
							speed: 3
						},
						repulse: {
							distance: 200,
							duration: 0.4
						},
						push: {
							particles_nb: 4
						},
						remove: {
							particles_nb: 2
						}
					}
				},
				retina_detect: true
			}}
		/>
	);
}
