const Tags =
['Lua', 'Ruby', 'Brainfuck', 'Perl', 'TeX', 'Ruby', 'OCaml', 'JSON', 'Ruby', 'Ruby', 'Ruby', 'C++', 'reStructuredText', 'Visual Basic', 'SQL', 'Visual Basic', 'Perl', 'Julia', 'Shell', 'Shell', 'CMake', 'Assembly', 'TeX', 'Diff', 'NumPy', 'R', 'TeX', 'Cuda', 'Python traceback', 'FORTRAN', 'HTML+ERB', 'Markdown', 'R', 'HTML', 'JavaScript', 'C++', 'Emacs Lisp', 'C++', 'HTML', 'Perl', 'C++', 'LLVM', 'Ruby', 'Python', 'Objective-C', 'Ruby', 'Java', 'Common Lisp', 'C++', 'Perl', 'Perl', 'Pascal', 'HTML+Django', 'C++', 'TeX', 'PHP', 'TeX', 'Python', 'HTTP', 'Java Server Pages', 'Sage', 'Cython', 'Haskell', 'Matlab', 'Visual Basic', 'HTML+PHP', 'Clojure', 'PHP', 'C++', 'C#', 'TypeScript', 'TeX', 'SCSS', 'Kotlin', 'TeX', 'Python', 'Haskell', 'C++', 'PHP', 'TeX', 'PHP', 'Arduino', 'Ruby', 'HTML+Django', 'JavaScript', 'AppleScript', 'reStructuredText', 'JavaScript', 'YAML', 'Makefile', 'Python', 'C++', 'Go', 'TeX', 'VimL', 'HTML', 'Perl', 'Nginx', 'ASP', 'FORTRAN', 'TeX', 'Less', 'Perl', 'Ruby', 'Ruby', 'Pascal', 'Objective-C', 'xBase', 'Brainfuck', 'PHP', 'Python', 'TeX', 'Scala', 'PHP', 'XML', 'Visual Basic', 'ApacheConf', 'Ada', 'Rust', 'Sass', 'PHP', 'C', 'Visual Basic', 'CoffeeScript', 'CSS', 'C++', 'Shell', 'YAML', 'Ruby', 'Elm', 'Python', 'C#', 'Perl', 'Perl', 'Visual Basic', 'Pascal', 'Kotlin', 'Io', 'Ruby', 'C++', 'Emacs Lisp', 'Ruby', 'Kotlin', 'C',

'Blockchain',
'Cryptocurrency',
'Quantum Mechanics',
'Quantum Computing',
'Machine Learning',
'Deep Learning',
'Artificial Intelligence',
'Dev Ops',
'Automation'


]



export default Tags
