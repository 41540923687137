const data = [
  {
    text: 'Registrations for both Students And Projects Begin',
    date: '26 November 2020',
  },
  {
    text: 'End of Registrations',
    date: '6 December 2020',
  },
  {
    text: 'Coding Period starts',
    date: '6 December 2020',
  },
  {
    text: 'Mid Evaluations',
    date: '20 December 2020',
    // link: {
    // 	url: "",
    // 	text: "Read More",
    // },
  },
  {
    text: 'Coding Period Ends. However, we encourage you to keep contributing to the projects',
    date: '4 January 2021',
    // link: {
    // 	url: "",
    // 	text: "Read More",
    // },
  },
  {
    text: 'Deadline to submit End evals report(for students who have cleared Mid Evals)',
    date: '11 PM IST, 9 January 2021',
    // link: {
    // 	url: "",
    // 	text: "Read More",
    // },
  },
];

export default data;
